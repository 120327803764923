import moment from "moment";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type onChangeInputFunc = (e:string) => void

type InputType = {
    placeholder?:string,
    label?: string,
    id?: string,
    value?:string,
    error?:string|null,
    disabled?:boolean,
    onChangeInput?: onChangeInputFunc,
    inputClass?:string,
}

const DatePickerField = ({
    placeholder,
    label,
    id,
    value,
    error,
    onChangeInput,
    disabled = false,
    inputClass
} : InputType) => {
    const Id = id ?? label;

    const [startDate, setStartDate] = useState(new Date());

    const onChangeDate = (date:any) => {
        setStartDate(date);
        onChangeInput && onChangeInput(date.toISOString());
    }

    const PlaceholderText = placeholder ?? `dd/mm/yyyy`;

    return(
        <label htmlFor={Id} className="label">
            <span>{label}</span>
            <div className={`relative justify-between w-full bg-white input ${inputClass} ${error && 'border-red-500'} ${disabled && 'text-light-black'}`}>
                    <span className="">{value ? moment(value).format('DD/MM/YYYY') : PlaceholderText}</span>                    
                    <div className="">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 2H11.5V1.5C11.5 1.36739 11.4473 1.24021 11.3536 1.14645C11.2598 1.05268 11.1326 1 11 1C10.8674 1 10.7402 1.05268 10.6464 1.14645C10.5527 1.24021 10.5 1.36739 10.5 1.5V2H5.5V1.5C5.5 1.36739 5.44732 1.24021 5.35355 1.14645C5.25979 1.05268 5.13261 1 5 1C4.86739 1 4.74021 1.05268 4.64645 1.14645C4.55268 1.24021 4.5 1.36739 4.5 1.5V2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H13C13.2652 14 13.5196 13.8946 13.7071 13.7071C13.8946 13.5196 14 13.2652 14 13V3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2ZM4.5 3V3.5C4.5 3.63261 4.55268 3.75979 4.64645 3.85355C4.74021 3.94732 4.86739 4 5 4C5.13261 4 5.25979 3.94732 5.35355 3.85355C5.44732 3.75979 5.5 3.63261 5.5 3.5V3H10.5V3.5C10.5 3.63261 10.5527 3.75979 10.6464 3.85355C10.7402 3.94732 10.8674 4 11 4C11.1326 4 11.2598 3.94732 11.3536 3.85355C11.4473 3.75979 11.5 3.63261 11.5 3.5V3H13V5H3V3H4.5ZM13 13H3V6H13V13Z" fill="#888888"/>
                        </svg>
                    </div>
                    <div className="absolute top-0 left-0 right-0 w-full h-full">
                    <DatePicker
                     //dateFormat="yyyy" 
                    //placeholderText="dd/MM/yyyy" 
                    onChange={(date) => onChangeDate(date??new Date())} 
                    selected={startDate} 
                    wrapperClassName="w-full" 
                    className="absolute top-0 left-0 w-full h-full opacity-0"
                />
                    </div>
                </div>
            {error && <p className="my-1 text-[13px] text-red-500 capitalize label font-[400]">{error}</p>}
        </label>
    );
}

export default DatePickerField;