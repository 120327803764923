import CryptoJS from 'crypto-js';

const app_key : string | undefined = process.env.REACT_APP_KEY;

const SmoothScroll = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
}

const encrypt = (data:string) => {
    return CryptoJS.AES.encrypt(data, app_key ?? '').toString();
}

const decrypt = (data:string) => {
    return CryptoJS.AES.decrypt(data, app_key ?? '').toString(CryptoJS.enc.Utf8);
}

const EmptyFormInput = (formSchema: any) => {
    return Object.keys(formSchema.fields).reduce((acc:any, item:any) => {
        acc[item] = '';
        return acc;
      }, {});
}

const getProductStatus = (status:string) => {
  switch (status) {
      case 'published':
          return 'Published';
      case 'closed':
          return 'Closed';
      default:
          return 'Pending';
  }
}

const formatNumber = (number:number|string) => {
  number = Number(number);
  if (number >= 1000000) {
      return Math.round(number / 1000000).toFixed(1).replace(/\.0$/, '') + 'm';
  } else if (number >= 1000) {
      return Math.round(number / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  } else {
      return number.toString();
  }
}

const numberWithCommas = (input: string | number, withDecimal = false, onlyDecimal = false) => {  
  if (withDecimal){
      let hasDecimal = input.toString().split('').indexOf('.') !== -1;

      if (! hasDecimal )  input += '.00';
  }
  
  let parts = input.toString().split('.');

  if ( withDecimal && onlyDecimal ){
      return parts[1].toString();
  }
  
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (! withDecimal) return parts[0];
  
  return parts.join('.');
}

const convertToBase64 = async (file:any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            if (typeof reader.result === 'string') {
              resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
            } else {
              // Handle the case where reader.result is not a string
              reject(new Error('Invalid file type'));
            }
          }
        reader.onerror = (error) => reject(error);
      });
}

const getFileSizeInMB = (file:any) : number => {
  return parseInt((file.size / (1024 * 1024)).toFixed(2));
}

const generateRandomNumber = (length:number) => {
  const min = Math.pow(10, length - 1);
  const max = Math.pow(10, length) - 1;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Naira = '₦';

const DurationTypes = ['Months', 'Years'].map((item: any) => ({value: item.toLocaleLowerCase(), name: item}));
const TransactionTypes = ['Debt Capital Market', 'Equity Capital Markets', 'Financial Advisory'].map((item: any) => ({value: item, name: item}));
const SecurityTypes = [
    'Commercial Paper', 'Public Bond', 
    'Private Bond', 'Rights Issue', 
    'Follow-on Offer', 'Initial Public Offer ("IPO")',
    'Merger', 'Acquisition', 'Divestment'
].map((item: any) => ({value: item, name: item}));
const RegulatorTypes = ['FMDQ Securities Exchange Limited', 'Securities & Exchange Commission'].map((item: any) => ({value: item, name: item}));
const TransactionRolesTypes = ['Lead', 'Joint'].map((item: any) => ({value: item, name: item}));
const CurrencyTypes = [
  {
    name: `${Naira}(NGN)`,
    value: 'NGN',
  }];

const getCurrency = (currency:string) => {
  switch (currency) {
      case 'NGN':
          return Naira;
      default:
          return Naira;
  }
}

const removeCommas = (value:string) => {
  return value.replace(/[^0-9]/g, '');
}

export {
    SmoothScroll,
    encrypt,
    decrypt,
    EmptyFormInput,
    convertToBase64,
    getFileSizeInMB,
    getProductStatus,
    getCurrency,
    generateRandomNumber,
    formatNumber,
    numberWithCommas,
    removeCommas,
    TransactionTypes,
    SecurityTypes,
    RegulatorTypes,
    TransactionRolesTypes,
    CurrencyTypes,
    DurationTypes,
}