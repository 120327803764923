import React,{ useState } from 'react';
import SideSlidingModal from '../../../components/modal/wrapper/side-sliding-modal';
import TransactionForm from './transaction-form';

type AddButtonType = {
    buttonDesign: JSX.Element,
    onSuccess?: (data:any) => void,
    onLoading?: (state:boolean) => void
}
const AddTransaction = ({buttonDesign, onSuccess, onLoading} : AddButtonType) => {
 
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onsetForceModalClose = (state:any) => {setModalOpen(state)}

    return (
        <SideSlidingModal buttonTrigger={buttonDesign} forceModalClose={modalOpen} onSetForceModalClose={onsetForceModalClose}>
          <div>
          <h2 className="page-title text-[20px]">Add New Transaction</h2>
          <p className="mt-4 text-light-black text-[14px] font-[450]">Enter relevant details and ignore fields that do not apply</p>
            <TransactionForm onLoading={onLoading} onSuccess={onSuccess} setModalOpen={setModalOpen}/>
          </div>
        </SideSlidingModal>
    )
}

export default AddTransaction