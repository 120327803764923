import { useEffect, useState } from "react";
import ApiRequest from "../../../../libs/ApiCall";
import moment from "moment";
import SideSlidingModal from "../../../../components/modal/wrapper/side-sliding-modal";

interface NotificationType{
    id: string,
    message: string,
    subject: string,
    createdAt: string,
    readAt: string | null,
}

const getShortContent = (content:string) => {
    return content.length > 50 ? `${content.slice(0, 50)}...` : content;
}

const AppNotification = () => {
    
    const [notifications, setNotifications] = useState<NotificationType[]>([]);
    
    const getNotifications = async () => {
        const request = await ApiRequest({endpoint: '/notification', method: 'GET'});
        request.status === '200' && setNotifications(request.data);
    }

    useEffect(() => {
        getNotifications();
    }, []);
    
    return (
        <>
        <h2 className="font-['Merriweather'] p-6">Messages from AIHN</h2>
            <div className="grid gap-4 px-3 h-[50em] overflow-y-auto ">
                {notifications.length === 0 && <p className="label">Messages from AIHN will be displayed here</p>}
                {notifications.map((notification, index) => (
                    <div key={index} className="border-b border-[#E7E7E7] pb-5">
                        <SideSlidingModal buttonTrigger={NotificationSummary(notification)}>
                            <div className="notification-section">
                                <h2 className="page-title text-[20px]">{notification.subject}</h2>
                                <p className="text-light-black text-[13px] mt-3 !mb-6">{moment(notification.createdAt).fromNow()}</p>
                                <p dangerouslySetInnerHTML={{__html: notification.message}}></p>
                            </div>
                        </SideSlidingModal>
                    </div>
                ))}
            </div>
        </>
    );
}

const NotificationSummary = (notification:NotificationType) => {
    return (
        <div className="text-[14px] hover:bg-white p-3 cursor-pointer rounded">
            <h3 className="font-[700]">{notification.subject}</h3>
            <p className="text-light-black text-[13px] mt-3 mb-5">{moment(notification.createdAt).fromNow()}</p>
            <p dangerouslySetInnerHTML={{__html: getShortContent(notification.message)}}></p>
        </div>
    );
}

export default AppNotification