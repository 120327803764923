import React, { useState } from "react";
import SpinnerLoader from "../loader/spinner-loader";
//import SpinnerLoader from "../loader/spinner-loader";

type DefaultModalType = {
    icon?: any,
    description?:string,
    modalButtonTriggerText?: string,
    modalButtonConfirmationText?: string,
    modalButtonCancelText?: string,
    onConfirmationAction?: (args?: any) => void,
    buttonTriggerClass?: string,
    buttonTriggerClassIcon?: any,
    onButtonTriggerClick?: () => void,
    args?: any,
}

const DefaultModal = ({
    icon,
    description = 'What do you want to do?',
    modalButtonTriggerText = 'Open Modal',
    modalButtonConfirmationText = 'Yes',
    modalButtonCancelText = 'No',
    onConfirmationAction,
    buttonTriggerClass= 'pryBtn',
    buttonTriggerClassIcon,
    onButtonTriggerClick,
    args,
}:DefaultModalType) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const processAction = async () => {
        setLoading(true);
        onConfirmationAction && await onConfirmationAction(args);
        setShowModal(false);
        setLoading(false);
    }

    return(
        <div className="relative">
            <span  onClick={() => {setShowModal(true); onButtonTriggerClick && onButtonTriggerClick()}} className={`${buttonTriggerClass} cursor-pointer`}>
                {buttonTriggerClassIcon}
                <span>{modalButtonTriggerText}</span>
            </span>
            {  (
                <div>
                    <div className={`${showModal?'flex':'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                    <div className="relative w-full max-w-md max-h-full p-4">
                        <div className="relative bg-white rounded-lg shadow">
                            <div className="p-4 text-center md:p-5">
                                <div className="flex justify-center w-full">
                                    {icon}
                                </div>
                                <h3 className="my-5 mb-5 text-lg font-normal text-tertiary">
                                    {description}
                                </h3>
                                <button disabled={loading} onClick={processAction} type="button" className={`${loading && 'opacity-[0.5]'} text-white space-x-2 bg-primary hover:bg-tertiary focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center`}>
                                    <span>{modalButtonConfirmationText} </span>
                                    {loading && <SpinnerLoader/>}
                                </button>
                                <button onClick={() => setShowModal(!showModal)} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 ">
                                    {modalButtonCancelText}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`fixed inset-0 z-40 bg-gray-900/50 ${showModal ? '' : 'hidden'}`}></div>
                </div>
            )}
        </div>
    );
}

export default DefaultModal