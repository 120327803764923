import { useState } from "react";
import InputField from "../../../../components/form/input-field";
import TextareaField from "../../../../components/form/textarea";
import TickerField from "../../../../components/form/ticker-field";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ApiRequest from "../../../../libs/ApiCall";
import { SmoothScroll } from "../../../../libs/helper-support";
import Tooltip from "../../../../components/tooltip";
import SpinnerLoader from "../../../../components/loader/spinner-loader";

type RegisterFormType = {
    onSuccess: (data:any) => void
}

const RegisterForm = ({onSuccess} : RegisterFormType) => {
    const [sameAddress, setSameAddress] = useState<boolean>(false);
    type representativesType = {
        name: string,
        position: string
        mobile_number:string,
        email_address:string,
        is_primary: boolean
    }
    const [representatives, setRepresentatives] = useState<representativesType[]>([{is_primary:false}, {is_primary:false}] as representativesType[]);

    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

    const removeRepresentative = (index:number) => {
        const newRepresentatives = [...representatives];
        newRepresentatives.splice(index, 1);
        setRepresentatives(newRepresentatives);
    };

    const onChangeRepresentativeInput = (index:number, field:string, e:React.ChangeEvent<HTMLInputElement>) => {
        const newRepresentatives = representatives.map((rep, i) => {
            if (i === index) {
                const target = e.target as HTMLInputElement | HTMLTextAreaElement;
                return { ...rep, [field]: target.value };
            }
            return rep;
        });
        setRepresentatives(newRepresentatives);
    };

    const onPrimaryContact = (index:number) => {
        const newRepresentatives = representatives.map((rep, i) => {
            return { ...rep, is_primary: i === index };
        });
        setRepresentatives(newRepresentatives);
    }

    const onSameAddress = (state:boolean) => {
        setSameAddress(state);
        setValue('contactAddress', getValues('registeredAddress'));
    }

    const onRegisteredAddress = (value:string) => {
        sameAddress && setValue('contactAddress', value);
    }

    const formSchema = yup.object({
        nameInstitution: yup.string().required('Name of institution is required'),
        registeredAddress: yup.string().required('Registered address is required'),
        contactAddress: yup.string().required('Contact address is required'),
        secRegistrationNumber: yup.string().required('SEC registration number is required'),
        contactRepresentatives: yup.array().of(
            yup.object({
                name: yup.string(),
                position: yup.string(),
                mobile_number: yup.string(),
                email_address: yup.string(),
                is_primary: yup.boolean()
            })
        )
    });

    const  {
        setValue,
        setError,
        formState: {errors},
        handleSubmit,
        getValues
    } = useForm({resolver: yupResolver(formSchema)}); 

    const onSubmitForm = async (data:any) => {
        data['contactRepresentatives'] = representatives;
        setLoadingSubmit(true);
        const request = await ApiRequest({endpoint: 'auth/register', method: 'POST', payload: data});
        setLoadingSubmit(false);
      
        if ( request.status === '200') {
            onSuccess(request.data);
            return;
        }
    
        if (request.status === "payloadValidationError") {
            for (const [field, messages] of Object.entries(request.data)) {
                const input_field : any = field ;
                const error_message : any = messages
                setError(input_field, { type: "manual", message: error_message.join(", ") });
                SmoothScroll();
            }
        }
    }
    return (
        <form onSubmit={handleSubmit(onSubmitForm)} className="grid gap-y-4">
            <div>
                <h4 className="page-title text-[18px]">Company Details</h4>
                <div className="grid mt-5 gap-y-6">
                    <InputField
                        label='Name of Institution'
                        placeholder="Enter company name"
                        error={errors.nameInstitution?.message}
                        onChangeInput={(e) => [setValue('nameInstitution', e.target.value), setError('nameInstitution', {message: ''})]}
                        value={getValues('nameInstitution')}
                    />
                    <InputField
                        label='SEC Registration Number'
                        error={errors.secRegistrationNumber?.message}
                        onChangeInput={(e) => [setValue('secRegistrationNumber', e.target.value), setError('secRegistrationNumber', {message: ''})]}
                        value={getValues('secRegistrationNumber')}
                    />
                    <TextareaField
                        label='Registered Address'
                        placeholder='Enter full address'
                        error={errors.registeredAddress?.message}
                        onChangeInput={(e) => [setValue('registeredAddress', e.target.value), onRegisteredAddress(e.target.value), setError('registeredAddress', {message: ''})]}
                        value={getValues('registeredAddress')}
                    />
                    <div className="mt-10">
                        <TickerField
                            label="Contact Address is the same as above"
                            onChangeInput={(state) => onSameAddress(state)}
                            checked={sameAddress}
                        />
                    </div>
                    <div className={`${sameAddress ? 'hidden' : ''} mb-10`}><TextareaField
                        label='Contact Address'
                        placeholder='Enter full address'
                        error={errors.contactAddress?.message}
                        value={getValues('contactAddress')}
                        onChangeInput={(e) => [setValue('contactAddress', e.target.value), setError('contactAddress', {message: ''})]}
                    /></div>
                </div>
            </div>
            <div className="mt-4">
                <h4 className="page-title text-[18px]">Contact Details of Representatives</h4>
                <p className="text-light-black text-[14px] mt-2">At least 2 contacts required</p>
                {errors.contactRepresentatives && <p className="my-1 text-sm text-red-500 label">{errors.contactRepresentatives.message}</p>}
                {representatives.map((rep, index) => (
                            <div key={index} className="p-4 mt-4 bg-white border rounded">
                                <div className="grid gap-4 lg:grid-cols-2">
                                    <div>
                                        <InputField
                                            label="Name"
                                            onChangeInput={(e) => [onChangeRepresentativeInput(index, 'name', e), setError('contactRepresentatives', {message: ''})]}
                                            value={rep.name}
                                            placeholder="Enter first and last name"
                                            error={errors.contactRepresentatives?.[index]?.name?.message}  
                                        />
                                    </div>
                                    <div>
                                        <InputField
                                            label="Position"
                                            onChangeInput={(e) => onChangeRepresentativeInput(index, 'position', e)}
                                            value={rep.position}
                                            placeholder="Enter job title/position"
                                            error={errors.contactRepresentatives?.[index]?.position?.message}  
                                        />
                                    </div>
                                    <div>
                                        <InputField
                                            label="Mobile number"
                                            onChangeInput={(e) => onChangeRepresentativeInput(index, 'mobile_number', e)}
                                            type="number"
                                            value={rep.mobile_number}
                                            placeholder="E.g. +2348031231234"
                                            error={errors.contactRepresentatives?.[index]?.mobile_number?.message}  
                                        />
                                    </div>
                                    <div>
                                        <InputField
                                            label="Email address"
                                            onChangeInput={(e) => onChangeRepresentativeInput(index, 'email_address', e)}
                                            type="email"
                                            value={rep.email_address}
                                            placeholder="name@company.com"
                                            error={errors.contactRepresentatives?.[index]?.email_address?.message}  
                                        />
                                    </div>
                                    <div className="flex items-center space-x-3">
                                        <TickerField
                                            name="primary_contact"
                                            onChangeInput={() => onPrimaryContact(index)}
                                            label="Set as primary contact"
                                            checked={rep.is_primary}
                                            value={index.toString()}
                                            type="radio"
                                        />
                                        <Tooltip text="The portal account will be created with the email address of the primary contact"/>
                                    </div>
                                </div>
                                <p className="flex justify-end mt-2 font-bold label">
                                    {index > 1 && (
                                        <div onClick={() => removeRepresentative(index)} className="cursor-pointer text-red-800 text-sm font-[400] hover:underline w-fit capitalize">
                                           
                                            <span>Remove representative</span>
                                        </div>
                                    )}
                                </p>
                            </div>
                        ))}
                <div className="mt-4">
                    <button type="button" onClick={() => setRepresentatives([...representatives, {} as representativesType])} className="flex items-center space-x-2 w-fit text-[#0099CE]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75H12.75V20.25C12.75 20.4489 12.671 20.6397 12.5303 20.7803C12.3897 20.921 12.1989 21 12 21C11.8011 21 11.6103 20.921 11.4697 20.7803C11.329 20.6397 11.25 20.4489 11.25 20.25V12.75H3.75C3.55109 12.75 3.36032 12.671 3.21967 12.5303C3.07902 12.3897 3 12.1989 3 12C3 11.8011 3.07902 11.6103 3.21967 11.4697C3.36032 11.329 3.55109 11.25 3.75 11.25H11.25V3.75C11.25 3.55109 11.329 3.36032 11.4697 3.21967C11.6103 3.07902 11.8011 3 12 3C12.1989 3 12.3897 3.07902 12.5303 3.21967C12.671 3.36032 12.75 3.55109 12.75 3.75V11.25H20.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12Z" fill="#0099CE"/>
                        </svg>
                        <span>Add another contact</span>
                    </button>
                </div>
            </div>
            <div className="flex justify-center">
                <button disabled={loadingSubmit} className={`${loadingSubmit && 'opacity-[0.5]'} pryBtn w-fit`}>
                    Continue to Payment {loadingSubmit && <SpinnerLoader/>}
                </button>
            </div>
        </form>
    );
}

export default RegisterForm