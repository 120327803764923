import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import GuestLayout from "../../components/layouts/guest";
import RegisterForm from "./partials/register/form";
import Payment from "./partials/register/payment";
import Logo from "../../components/logo";
import RegSuccess from "./partials/register/sucess";
import ApiRequest from "../../libs/ApiCall";
import { ErrorFlash } from "../../libs/flash-notification";

const Register = () => {
    const [page, setPage] = useState<string>('');

    const [searchParams, setSearchParams] = useSearchParams();

    const reference = searchParams.get('reference');

    interface PaymentTypeRF{
        reference: string,
        url: string,
        accessCode: string,
        paymentTypes: any[],
        amount: string,
    }
    const[payment, setPayment] = useState({}as PaymentTypeRF);

    const onRegSuccess = (data:any) => {
        setPayment(data);
        setPage('payment');
    }

    const verifyTransactionSuccess = async () => {
        if ( ! reference ){
            setPage('form');
            return
        }
                
        const request = await ApiRequest({endpoint: `auth/verify-payment/${reference}`, method: 'GET'});

        if ( request.status === '302' ) return;

        if ( request.status !== '200' ) { 
            ErrorFlash('Transaction processing.');
            return;
        }
        
        if(request.data.status === 'success'){
            setPage('completed');
        }
    }

    useEffect(() => { verifyTransactionSuccess() }, []);
    
    return (
        <GuestLayout>
            <div className="mx-auto lg:w-1/2">
                <div className="flex justify-center mb-5"><Logo/></div>
                <h1 className="text-center page-title">Apply to Join</h1>
                <div className="mt-10 font-[merriweather] text-light-black font-[700] text-[14px] flex justify-center space-x-5">
                    <div className={`${page === 'form' && 'text-secondary'}`}>Submit details</div>
                    <div><svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.2806 8.53063L11.5306 15.2806C11.3899 15.4214 11.199 15.5004 11 15.5004C10.801 15.5004 10.6101 15.4214 10.4694 15.2806C10.3286 15.1399 10.2496 14.949 10.2496 14.75C10.2496 14.551 10.3286 14.3601 10.4694 14.2194L15.9397 8.75H1.25C1.05109 8.75 0.860322 8.67099 0.71967 8.53034C0.579018 8.38968 0.5 8.19892 0.5 8C0.5 7.80109 0.579018 7.61033 0.71967 7.46968C0.860322 7.32902 1.05109 7.25 1.25 7.25H15.9397L10.4694 1.78063C10.3286 1.6399 10.2496 1.44903 10.2496 1.25001C10.2496 1.05098 10.3286 0.860111 10.4694 0.71938C10.6101 0.57865 10.801 0.499588 11 0.499588C11.199 0.499588 11.3899 0.57865 11.5306 0.71938L18.2806 7.46938C18.3504 7.53903 18.4057 7.62175 18.4434 7.7128C18.4812 7.80385 18.5006 7.90144 18.5006 8C18.5006 8.09857 18.4812 8.19616 18.4434 8.28721C18.4057 8.37826 18.3504 8.46098 18.2806 8.53063Z" fill="#888888"/>
                    </svg>
                    </div>
                    <div className={`${page === 'payment' && 'text-secondary'}`}>Make Payment</div>
                    <div><svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.2806 8.53063L11.5306 15.2806C11.3899 15.4214 11.199 15.5004 11 15.5004C10.801 15.5004 10.6101 15.4214 10.4694 15.2806C10.3286 15.1399 10.2496 14.949 10.2496 14.75C10.2496 14.551 10.3286 14.3601 10.4694 14.2194L15.9397 8.75H1.25C1.05109 8.75 0.860322 8.67099 0.71967 8.53034C0.579018 8.38968 0.5 8.19892 0.5 8C0.5 7.80109 0.579018 7.61033 0.71967 7.46968C0.860322 7.32902 1.05109 7.25 1.25 7.25H15.9397L10.4694 1.78063C10.3286 1.6399 10.2496 1.44903 10.2496 1.25001C10.2496 1.05098 10.3286 0.860111 10.4694 0.71938C10.6101 0.57865 10.801 0.499588 11 0.499588C11.199 0.499588 11.3899 0.57865 11.5306 0.71938L18.2806 7.46938C18.3504 7.53903 18.4057 7.62175 18.4434 7.7128C18.4812 7.80385 18.5006 7.90144 18.5006 8C18.5006 8.09857 18.4812 8.19616 18.4434 8.28721C18.4057 8.37826 18.3504 8.46098 18.2806 8.53063Z" fill="#888888"/>
                    </svg>
                    </div>
                    <div className={`${page === 'completed' && 'text-secondary'}`}>Application Complete</div>
                </div>
                <hr className="mt-12 border-[#E7E7E7] mb-10"/>
                {page === 'form' && <RegisterForm onSuccess={onRegSuccess}/>}
                {page === 'payment' && <Payment option={payment} />}
                {page === 'completed' && <RegSuccess/>}
            </div>
        </GuestLayout>
    );
}

export default Register