import React,{ useEffect, useState } from 'react';
import SideSlidingModal from '../../../components/modal/wrapper/side-sliding-modal';
import TransactionForm from './transaction-form';
import TransactionType from '../../../types/transaction-type';
import { SmoothScroll } from '../../../libs/helper-support';

type AddButtonType = {
    buttonDesign: JSX.Element,
    onSuccess?: (data:any) => void,
    onLoading?: (state:boolean) => void,
    transaction: TransactionType,
}
const EditTransaction = ({buttonDesign, onSuccess, onLoading, transaction} : AddButtonType) => {
 
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onsetForceModalClose = (state:any) => {setModalOpen(state)}

    return (
        <SideSlidingModal buttonTrigger={buttonDesign} forceModalClose={modalOpen} onSetForceModalClose={onsetForceModalClose}>
          <div>
          <h2 className="page-title text-[20px] capitalize">Edit transaction: {transaction.issuer}</h2>
          <p className="mt-4 text-light-black text-[14px] font-[450]">Enter relevant details and ignore fields that do not apply</p>
            <TransactionForm onLoading={onLoading} onSuccess={onSuccess} setModalOpen={setModalOpen} modalOpen={modalOpen} transaction={transaction} isEdit={true}/>
          </div>
        </SideSlidingModal>
    )
}

export default EditTransaction